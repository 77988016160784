<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.75 2C7.16421 2 7.5 2.33579 7.5 2.75V6.00049H10.75C11.1642 6.00049 11.5 6.33628 11.5 6.75049C11.5 7.1647 11.1642 7.50049 10.75 7.50049H7.5V10.75C7.5 11.1642 7.16421 11.5 6.75 11.5C6.33579 11.5 6 11.1642 6 10.75V7.50049H2.75C2.33579 7.50049 2 7.1647 2 6.75049C2 6.33627 2.33579 6.00049 2.75 6.00049L6 6.00049V2.75C6 2.33579 6.33579 2 6.75 2Z"
      fill="white"
      shape-rendering="geometricPrecision"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.501 4.49976C12.4931 4.49976 12.4853 4.49977 12.4775 4.49979C11.7871 4.50177 11.1448 4.04692 11.0363 3.36515L10.997 3.11826C11.1633 3.09178 11.331 3.06963 11.5 3.05192C11.6655 3.03458 11.8322 3.02149 12 3.01276C12.166 3.00412 12.333 2.99976 12.501 2.99976C17.7477 2.99976 22.001 7.25305 22.001 12.4998C22.001 17.7465 17.7477 21.9998 12.501 21.9998C7.25427 21.9998 3.00098 17.7465 3.00098 12.4998C3.00098 12.3322 3.00532 12.1656 3.01391 12C3.02262 11.8322 3.03569 11.6655 3.05301 11.5C3.07073 11.3308 3.09289 11.1628 3.11939 10.9963L3.36629 11.0355C4.04807 11.144 4.50295 11.7863 4.50101 12.4767C4.50099 12.4844 4.50098 12.4921 4.50098 12.4998C4.50098 14.4387 5.19076 16.2165 6.33831 17.6012L17.6024 6.33709C16.2177 5.18954 14.4399 4.49976 12.501 4.49976ZM18.6632 7.3977L7.39892 18.6619C8.78372 19.8098 10.5618 20.4998 12.501 20.4998C16.9193 20.4998 20.501 16.918 20.501 12.4998C20.501 10.5606 19.811 8.7825 18.6632 7.3977Z"
      fill="white"
      shape-rendering="geometricPrecision"
    />
  </svg>
</template>
