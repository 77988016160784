import { $t } from '@/i18n';

export function formatStreetAndFloor(street?: string, floor?: string): string {
  if (!street) {
    return '';
  }
  if (floor) {
    return $t('common.address-format.street-and-floor', {
      street,
      floor,
    });
  }
  return street;
}

export function formatStreetAndStreetNumber(
  address1?: string,
  address2?: string,
): string {
  if (!address1) {
    return '';
  }
  if (!address2) {
    return address1;
  }
  return $t('common.address-format.street-and-street-number', {
    street: address1,
    streetNumber: address2,
  });
}

export function formatZipCodeAndCity(zipCode?: string, city?: string): string {
  if (!zipCode || !city) {
    return '';
  }

  return $t('common.address-format.zip-code-and-city', {
    zipCode,
    city,
  });
}

export function formatFullName(firstName?: string, lastName?: string): string {
  if (!firstName || !lastName) {
    return '';
  }

  return $t('common.address-format.full-name', {
    firstName,
    lastName,
  });
}

export function formatPhoneNumberToHref(phoneNumber?: string): string {
  if (!phoneNumber) {
    return '';
  }

  // Filter all non-digit characters (^\d) (replacing them by empty string except plus sign (+).
  const formattedPhoneNumber = phoneNumber.replace(/[^\d+]/g, '');

  if (formattedPhoneNumber.length === 0) {
    return '';
  }

  return `tel:${formattedPhoneNumber}`;
}
