<template>
  <div class="drops-list-item-timeframe">
    <div class="drops-list-item-timeframe-icon">
      <slot name="icon" />
    </div>
    <div>
      <slot name="header" />
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.drops-list-item-timeframe {
  display: flex;
  align-items: center;
}
.drops-list-item-timeframe-icon {
  margin-top: 4px;
  margin-right: 10px;
}
</style>
